import React, { Component } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import AOS from "aos"
import 'aos/dist/aos.css';
import mainImg from "../../images/services/coach-hire-services-school-trips.jpg"
import NewsStrip from "../../components/news-strip"
import arrowSvg from "../../images/arrow-circle-right-solid.svg"

class ServicePage extends Component {

  componentDidMount(){
    AOS.init();
    window.addEventListener('load', AOS.refresh);
    // @NOTE: Hack to force AOS to work :(
    window.setTimeout(AOS.refresh, 1000);
  }

  componentDidUpdate() {
    AOS.refresh();
  }

  render() {
    return (
      <Layout>
        <SEO title="School coach hire" description="Book coach hire for your school trips with Tetley's Coaches - Leeds' oldest bus and coach company established 1949." />
        <div className="block-services inner-body-bg">
          <div className="body gutter page-content-parent page-article" style={{ background: 'white' }}>
            <div>
              <h1 className="page-content-heading">School Trips</h1>
            </div>

            <div className="news-article-body">

              <p>We have over 70 years experience transporting thousands of schoolchildren, teachers and parents on educational visits both locally and nationwide.</p>
              <p>Our fleet includes 16, 33, 49, 57 and 70 seat coaches plus a 77 seat double decker; all of which are fully seat-belted for your safety and peace of mind.</p>
              <p>Safety is our highest priority and it's often the simplest of things that count. Parking on the correct side of the road, so that children do not have to cross the road is one example of the care we take. We are Metro and North Yorkshire County Council approved. All of our drivers are currently undergoing CRB checks.</p>
              <p>
                <img className="service-img" src={mainImg} alt="School coach hire" />
              </p>
              <p>We know that schools are working to a tight budget. Our friendly and experienced sales team will be happy to discuss your itinerary and tailor your coach hire requirements and provide a no-obligation, no-pressure competitive quote.</p>
              <p>Most of our school coach hire is repeat business which is no surprise. It's a testament to our high level of customer care and safety, our experience, our punctuality and reliability, our vehicles (no scruffy double deckers here!) and our staff. We can be trusted to transport your most valuable asset, your children.</p>
              <p>If you're looking for quality and experience, we're the coach hire company to call. Established in 1949, Tetley's Coaches have provided comfortable, safe and reliable coach transport for over 70 Years.</p>
              <p className="info"><strong>Did you know?:</strong> Tetley's Coaches are the chosen provider of school transport for <a href="http://www.middletonrailway.org.uk/">Middleton Railway</a>.</p>
              <p className="text-center">
                <a className='btn btn-grow btn-quote' href="https://portal.tetleyscoaches.co.uk/Quotation/NewQuotation" target="_blank" rel="noopener noreferrer">
                  <span className='btn-quote__text'>Get quote</span>
                  <img className='btn-quote__icon' src={arrowSvg} alt="" />
                </a>
              </p>
            </div>
          </div>
        </div>

        <NewsStrip />

      </Layout>
    );
  }
}

export default ServicePage
